<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Vehicles
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="
            $_checkPermission(this.auth.permissions, 'Manage Vehicle', 'Create')
          "
        >
          <v-icon left> mdi-plus </v-icon> New Vehicle
        </v-btn>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          
            {{ item.staff ? item.staff.first_name + ' '  + item.staff.last_name : "Not assign"}}
          
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreVehicles,
  syncOfflineVehiclesChanges,
} from "@/services/vehiclesService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    loading: false,
    search: "",
    totalCount: 0,
    page: 1,
    limit: 10,
    headers: [
      {
        text: "Vehicle Number",
        align: "start",
        value: "vehicle_no",
      },
      { text: "Category", value: "category" },
      { text: "Model", value: "model" },
      { text: "Make", value: "make" },
      { text: "Class", value: "class" },
      { text: "Driver", value: "staff.first_name" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
  }),
  async created() {
    if (this.$_checkPermission(this.auth.permissions, "Manage Vehicle", "Read")) {
      this.loading = true;
      await syncOfflineVehiclesChanges();
      this.items = await fetchAndStoreVehicles();
      this.loading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      this.$router.push({
        name: "PageViewVehicle",
        params: { uuid: item.uuid },
        query: { bc: item.vehicle_no },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewVehicle" });
    },
  },
};
</script>